import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Features2 from '@solid-ui-blocks/Features/Block05'
import Clients from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import Content from '@solid-ui-blocks/Content/Block01'
import Tabs from '@solid-ui-components/Tabs'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const OurServices = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Our Services' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['parentHelpline']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Divider space='4' />
      <Testimonials content={content['ourServices']} customHeight="25vw" customWidth />
      <Divider space='5' />
      <Divider space='4' />
      <Features2 content={content['doe-writing']}></Features2>
      <Divider space='5' />
      <Clients content={content['book-services']} />
      <Divider space='5' />
      <Container variant='full' sx={styles.contentContainer}>
        <Content content={content['missed-school']}/>
      </Container>
      <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={5}>
          <FeatureTwo content={content['mentorship-5th']} />
          <FeatureTwo content={content['mentorship-6th']} />
        </Tabs>
      </Container>
      <Divider space='4' />
      <Divider space='5' />
      <FeatureOne content={content['parents']} reverse/>
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='full' sx={styles.contentContainer}>
        <Content content={content['home-schooling']}/>
      </Container>
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageOurServicesBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/services", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default OurServices
